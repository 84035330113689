<style lang="scss">
    @import "~@/assets/css/var";

    .login-type {
        margin: 25px 0 -25px;
        .el-tabs__header {
            margin: 0;
        }
        .el-tabs__nav {
            display: flex;
            width: 100%;
        }
        .el-tabs__item {
            flex: 1;
            font-size: 16px;
            text-align: center;
            padding: 0 !important;
        }

        &-panel {
            position: absolute;
            z-index: -1;
            opacity: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #fff;
            &.active {
                opacity: 1;
                z-index: 1;
            }
            &:hover {
                .reload {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #ccc;
                    text-shadow: 0 0 3px #000;
                    font-size: 14px;
                }
            }

            .container {
                position: relative;
                margin: 0 auto;
                width: 220px;
            }

            .reload {
                display: none;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: #00000044;
            }
        }
    }
</style>

<style lang="scss">
    .agreement-popup {
        padding: 0 15px 50px;
        .title {
            text-align: center;
            font-size: 20px;
            padding-bottom: 30px;
        }
        h1 {
            font-size: 18px;
        }
        h2 {
            font-size: 16px;
        }
        p {
            font-size: 14px;
            line-height: 1.6;
            text-indent: 28px;
        }
    }
</style>

<template>
    <common-part title="欢迎登录淘数网">
        <el-tabs class="login-type" v-model="active">
            <el-tab-pane label="账户登录" name="account"></el-tab-pane>
            <el-tab-pane label="扫码登录" name="qrcode"></el-tab-pane>
        </el-tabs>

        <el-form ref="form" label-position="top" label-width="80px" :model="form" :rules="rules">
            <div style="position: relative;">

                <div class="login-type-panel" :class="{active: active === 'qrcode'}" v-loading="imgLoading">
                    <div class="container">
                        <img :src="imgUrl" alt="">
                        <!--<div class="reload" @click="getImg">-->
                            <!--<i class="el-icon-refresh"></i>重新读取-->
                        <!--</div>-->
                    </div>
                </div>

                <el-form-item label="手机号" prop="phone">
                    <el-input name="phone" v-model="form.phone" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input name="password" v-model="form.password" type="password" placeholder="请输入密码" show-password></el-input>
                </el-form-item>
                <div>
                    <el-link type="primary" :underline="false" @click="$router.push('/register')">免费注册</el-link>
                    <el-link style="float: right;" type="primary" @click="$router.push('/find')" :underline="false">忘记密码？</el-link>
                </div>

                <div style="display:flex;padding-top: 20px;">
                    <el-checkbox v-model="agreement.agree">同意</el-checkbox>
                    <el-link size="normal" @click="agreement.show = true">《淘数网用户协议》</el-link>
                    <p style="font-size: 14px;">和</p>
                    <el-link size="normal" @click="disclaimer.show = true">《免责声明》</el-link>
                </div>

                <div class="login-btn" style="padding-top: 20px;">
                    <el-button type="primary" @click="submit()" :loading="loading">登录</el-button>
                    <el-button @click="$router.push('/')">直接进入<i class="el-icon-d-arrow-right"></i></el-button>
                </div>
            </div>
        </el-form>

        <clear-drawer :show.sync="agreement.show" title="淘数网用户协议" hide-footer size="50%">
            <section class="agreement-popup">
                <p>欢迎使用淘数网，本协议自开始使用网站，并成为网站的注册用户时即产生法律效力。上海淘数网科技有限公司，以下简称“淘数网”。本协议包括淘数网网站内容的使用政策及淘数网隐私政策。您在使用淘数网提供的各项服务之前，应仔细阅读本服务公约。如您不同意本服务公约或随时对其的修改，您可以主动取消淘数提供的服务；您一旦使用淘数服务，即视为您已了解并完全同意本服务公约各项内容，并成为淘数网用户（以下简称“用户”）。</p>

                <h1>一、引言</h1>
                <h2>1.1 宗旨</h2>
                <p>淘数网是一款服务大众的药品相关信息查询工具,方便用户实时查询药品的相关信息，提供44个数据信息的查询、下载功能。</p>
                <h2>1.2 协议的生效与修改</h2>
                <p>本协议在您开始使用网站，并成为网站的注册用户时即产生法律效力。您一旦使用淘数网网站即视为全权授权淘数网为您获取、汇编、整理、提供政府公共数据。</p>
                <p>无论有无其他约定，用户注册淘数网账号即表示用户已接受了本协议的全部的条款和条件及淘数网随时对其所做的修改，并同意接受淘数网网站现有的及不时更新的或新发布的用户服务条款和隐私权政策以及其他各项规则、制度等的约束。</p>
                <p>用户理解并同意，淘数网有权基于业务功能、使用规则、法律法规及监管要求、更好的提供服务、经营策略等原因随时修改和（或）补充前述条款和条件，并只需将之公示于其官网，而无需征得用户的事先同意且无需另行通知用户，修改后的条款和条件自公示于官网后即时生效。如果用户不同意淘数网对本协议相关条款所做的修改和条件，用户应停止使用服务，如在淘数网修改相关条款并将之公示于网站后，用户继续使用服务应被视作已接受了修改后的条款。</p>
                <p>淘数网有权随时调整或限制境外（即非中国大陆）用户的部分功能使用，包括但不限于搜索功能、导出数据、下载报告等，请您及时采取措施避免个人损失。</p>

                <h1>二、义务</h1>
                <h2>2.1 服务资格</h2>
                <p>为使用“服务”，您同意:</p>
                <p>(1) 您必须满足18岁，请未成年人（18岁以下）勿向本网站提供任何个人资料或使用本服务。 </p>
                <p>(2) 您只能拥有一个淘数网帐户；</p>
                <p>(3) 淘数网有根据法律法规或在合理状况下限制您使用“服务”。</p>

                <h2>2.2 会员资格与义务</h2>
                <p>用户帐号、密码及完成本服务的注册程序之后，您将选择一个密码及帐号。维持密码及帐号的机密安全，是您的责任。您应对所有用您的密码及帐号的活动负完全的责任。您同意：</p>
                <p>您的密码或帐号遭到未获授权的使用，或者发生其他任何安全问题时，您将立即通知套淘数网；</p>
                <p>一个账户在同一时间内仅能登录一台同一类型设备（如您使用电脑登录，则同一账号同一时间仅能在一台电脑上登录，但您同时可以在手机上登录），否则您将被迫下线而我们无需承担任何责任，该次下线后的再次登录将仅能使用手机验证码方式。用户使用时，不得存在非人为的连续、频繁的查询操作行为，否则我们的后台将自动对您的账号封号且无需向您承担任何责任。若您暂时不进行查询操作，请主动安全退出登录以保障您的账户安全。如您未能依前述规定行事，对于任何人利用该密码及帐号及您不当操作所进行的任何行为，您应负完全的责任。对于因此而产生的任何损失或损害，淘数网不承担任何责任；</p>
                <p>用户不得修改、改编、翻译我们的系列产品和服务，或创作我们系列产品和服务的派生作品，或通过反编译、反向工程、反汇编或其它方式从我们系列产品和服务中得到源代码。若用户存在上述情形，则我们有权终止提供服务并要求保留向用户主张损失的权利。用户行为若涉嫌犯罪的，将交由司法机关处理。</p>
                <p>您不得以营利为目的，将您在使用淘数网服务时获得的服务信息或该服务自身向第三方出售或以其他形式提供。否则，淘数网有权停止向您提供服务。</p>
                <p>在使用淘数网及相关服务的过程中，我们可能会要求您提供或授权我方收集您的个人信息，包括但不限于您的姓名、电话号码等，您有权拒绝向我们提供这些信息，或者撤回您对这些信息的授权同意。请您了解，拒绝或撤回授权同意，将可能导致您无法使用淘数网或相关的特定功能。</p>
                <p>非经过您的同意或法律法规的规定，我们不会向第三方共享、提供、转让您的个人信息、查询记录及使用习惯。</p>
                <h2>2.3 VIP会员服务条款</h2>
                <p>（一）成为VIP会员的方式 </p>
                <p>（1）您必须先填写真实、准确信息注册成为淘数网用户。您所填写的内容与个人资料必须真实有效，否则我们有权拒绝您的申请或撤销您的VIP会员资格，并不予任何赔偿或退还VIP会员服务费。VIP会员的个人资料发生变化，应及时修改注册的个人资料，否则由此造成的VIP会员权利不能全面有效行使的责任由VIP会员自己承担，淘数网有权因此取消您的VIP会员资格，并不予退还VIP会员费或其他任何形式的任何赔偿。</p>
                <p>（2）用户可通过各种已有和未来新增的渠道成为淘数网VIP会员，通过淘数网或淘数网APP提示的方式进行支付，成为VIP会员。在用户使用具体某种方式成为VIP会员时，须阅读并确认接受相关的服务条款和使用方法。淘数网在此声明：未授权任何第三方销售淘数网VIP会员，也未在任何第三方平台销售VIP会员，任何通过非淘数网渠道购买的VIP账户，淘数网有权回收，并有权追究其法律责任。</p>
                <p>（3）淘数网VIP会员账号所有权归淘数网所有，VIP会员拥有淘数网账号的有限使用权。成为VIP会员后，您有权利不接受淘数网的服务，可联系客服取消VIP会员服务，但VIP会员服务费不予退还。</p>
                <p>(二)服务说明及账号查询 </p>
                <p>（1）当VIP会员使用淘数网各单项服务时，VIP会员的使用行为视为其对各单项服务的服务条款以及淘数网在该单项服务中发出各类公告的同意。在此提醒，VIP会员下载数数量上限为：1000条/次。</p>
                <p>（2） 您明确了解并同意，淘数网VIP会员的付费方式为代收费运营商托收的付款方式，您通过此种付费方式付费可能存在一定的商业风险，包括但不限于不法分子利用您账户或银行卡等有价卡进行违法活动，该等风险均会给您造成相应的经济损失。您应自行承担向侵权方追究侵权责任和追究责任不能的后果。</p>
                <p>（3） 您应自行负责妥善且正确地保管、使用、维护您在淘数网申请取得的账户、账户信息及账户密码。非因淘数网原因致使您账户密码泄漏以及因您保管、使用、维护不当造成损失的，淘数网无须承担与此有关的任何责任。</p>
                <p>（4） 淘数网不对您因第三方的行为或不作为造成的损失承担任何责任，包括但不限于支付服务和网络接入服务、任意第三方的侵权行为。</p>
                <p>（5）一旦您成为淘数网VIP会员，即视为您认可该项服务标明之价格；成为淘数网VIP会员后，该项服务即时生效。VIP会员的增值服务标准以淘数网网站上标注的详细资费标价为准。您可以通过登录淘数网VIP会员中心免费查询您的账号信息详情。</p>
                <p>（三） VIP会员的义务</p>
                <p>（1）VIP会员服务有固定的服务使用期限，您一旦成为VIP会员即视为认可它的服务使用期限。VIP会员服务仅限于申请账号自行使用；VIP会员服务期内不能在淘数网帐号之间转移，禁止赠与、借用、转让或售卖。否则淘数网有权在未经通知的情况下取消转让账户、受让账户的VIP会员服务资格，由此带来的损失由VIP会员自行承担。 </p>
                <p>（2）若VIP会员的行为持续违反本协议或违反国家相关法律法规，或淘数网认为VIP会员行为有损淘数网或他人的声誉及利益，我们有权取消该VIP会员的VIP会员资格而无须给与任何补偿。 </p>
                <p>（3）淘数网内所有的数据信息来源于已公开的数据，仅供户查询之用。VIP用户导出药品相关信息后，不得用于买卖、交换等用途，若由此引起的任何纠纷由行为人承担，淘数网不承担任何责任。 </p>
                <p>（4）任何VIP会员不得使用带有非法、淫秽、污辱或人身攻击的含义污辱或人身攻击的昵称和评论，一经发现，淘数网有权取消其VIP会员资格而无需给与任何补偿和退费。 </p>
                <p>（四）VIP会员需签订VIP服务协议，见协议《淘数网VIP服务协议》。</p>

                <h1>三、关于费用退还</h1>
                <h2>3.1费用退还</h2>
                <p>VIP会员服务系网络商品和虚拟商品，您所支付的费用是您所购买的会员服务所对应的网络商品价格，而非预付款或者存款、定金、储蓄卡等性质，VIP会员服务一经开通后不可退款（如因VIP会员服务存在重大瑕疵导致您完全无法使用等淘数网违约情形、本协议另有约定、法律法规要求必须退款的或淘数网退款等除外）。淘数网在此特别提醒您，您在购买VIP会员服务之前应仔细核对账号信息、购买的服务内容、价格、服务期限等信息。</p>
                <h1>四、知识产权归属</h1>
                <p>除非淘数网另行声明，本网站（{{url}}）的所有产品、技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图表、版面设计、电子文档）的所有知识产权（包括但不限于版权、商标权、专利权、商业秘密）及相关权利，均归淘数网所有。</p>
                <p>未经淘数网书面授权许可，任何人不得擅自（包括但不限于：以不合理或不合法的方式抓取、复制、传播、展示、镜像、上载、下载、非通过淘数网购买）获取或使用淘数网平台的数据、信息和资料，或通过不当方式影响淘数网的正常服务，任何人不得擅自以软件程序自动获得淘数网数据。否则，淘数网将依法追究法律责任。</p>

                <h1>五、服务终止</h1>
                <p>用户或淘数网可根据实际情况中断服务。淘数网不需对任何个人或第三方负责而中断服务。用户若反对任何服务条款的建议或对后来的条款修改有异议，或对淘数网服务不满，用户可有以下的追索权：</p>
                <p>（1）不再使用淘数网服务；</p>
                <p>（2）通告淘数网停止该用户的服务。</p>
                <p>结束用户服务后，用户使用淘数网服务的权利马上终止。同时，淘数网不再对用户承担任何义务。</p>
                <h1>六、解释权</h1>
                <p>一切解释权归淘数所有。</p>
            </section>
        </clear-drawer>
        <clear-drawer :show.sync="disclaimer.show" title="淘数网免责声明" hide-footer size="50%">
            <section class="agreement-popup">
                <h2>概述</h2>
                <p>本网站数据均为基于公开数据整理计算结果，我们力求但不保证数据的准确性和完整性，请谨慎参考。本网站任何数据或者意见不构成对任何人的投资或者决策建议。使用者应当自主决策，风险自担。</p>
            </section>
        </clear-drawer>
    </common-part>
</template>

<script>
    import CommonPart from './CommonPart'
    export default {
        components: {CommonPart},
        watch: {
            active(val) {
                if (val === 'qrcode') {
                    this.getImg()
                } else {
                    this.imgKey = ''
                }
            },
        },
        data() {return {
            visitId: new Date().getTime(),
            pageId: 'login',
            pageName: '登录',

            url: window.location.origin,
            active: 'account',
            agreement: {
                agree: false,
                show: false,
            },
            disclaimer: {
                show: false,
            },
            loading: false,
            imgUrl: '',
            imgLoading: false,
            imgKey: '',
            form: {
                //17307080459
                phone: process.env.NODE_ENV === 'development' ? '18879100460' : '',
                password: process.env.NODE_ENV === 'development' ? 'abcd1234' : '',
            },
            rules: {
                phone: [
                    { required: true, message: '请输入手机号', trigger: ['blur', 'change'] },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: ['blur', 'change'] },
                ],
            },
        }},
        created() {
            this.$store.commit('clearUser')
            document.onkeydown = () => {
                if(window.event.keyCode === 13){
                    this.submit()
                }
            }

            this.$help.socket.send(this)
        },
        destroyed() {
            document.onkeydown = undefined
        },
        methods: {
            submit(form = {}) {
                if (!this.agreement.agree) {
                    this.$store.commit('error', '请查看淘数网用户协议')
                    return false
                }
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.$api.post('sys/sysUser/login', this.$help.joinObj(this.form, form))
                            .then(res => {
                                if (res.code === this.$variable.SUCCESS_CODE) {
                                    this.$store.commit('success', '登录成功')
                                    this.$help.token.set(`Bearer ${res.data}`)
                                    if (this.$route.query.url) {
                                        window.location.href = decodeURIComponent(this.$route.query.url)
                                    } else {
                                        this.$router.push('/')
                                    }
                                    this.loading = false
                                    // this.$help.socket.connect()

                                    window.location.reload()
                                } else if (res.code === 'N0001') {
                                    this.$confirm('当前账号已在其他设备登录，是否强制登录？', '提示', {
                                        confirmButtonText: '强制登录',
                                        cancelButtonText: '取消',
                                        type: 'warning'
                                    })
                                        .then(() => {
                                            this.submit({force: true})
                                        })
                                        .catch(() => {
                                            this.loading = false
                                        })
                                } else {
                                    this.$store.commit('error', res.message)
                                    this.loading = false
                                }
                            })
                            .catch(res => {
                                console.log(res)
                                this.loading = false
                            })
                    }
                });
            },

            getImg() {
                this.imgLoading = true
                this.$api.get('sys/sysUser/queryLoginQrCode')
                    .then(res => {
                        this.imgUrl = res.data.qrCode
                        this.imgKey = res.data.key

                        this.cycleApi()
                    })
                    .finally(() => {
                        this.imgLoading = false
                    })
            },

            cycleApi() {
                if (this.imgKey) {
                    this.$api.get('sys/sysUser/queryLoginWechatStatus', {randStr: this.imgKey})
                        .then(res => {
                            if (res.code === 'S0001') {
                                this.getImg()
                                this.$store.commit('error', '二维码已失效，正在刷新二维码')
                            } else if (res.data.status === 'login_fail') {
                                this.$store.commit('error', res.data.errorMsg)
                                this.getImg()
                            } else if (res.data.status === 'login_success') {
                                this.$store.commit('success', '登录成功')
                                this.$help.token.set(`Bearer ${res.data.token}`)
                                if (this.$route.query.url) {
                                    window.location.href = decodeURIComponent(this.$route.query.url)
                                } else {
                                    this.$router.push('/')
                                }
                            } else {
                                setTimeout(() => {
                                    this.cycleApi()
                                }, 1000)
                            }
                        })
                }
            },
        }
    }
</script>